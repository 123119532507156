export const environment = {
	production: false,
	stage: 'dev',
	url: 'https://portal.cancom.dev',
	keycloak: {
		url: 'https://sso.cancom.dev/auth/',
		realm: 'cancom-dev',
		clientId: 'reports.cc-mase-automation.de',
	},
	contactEMail: 'portal@ihre-meinung-interessiert-uns.net',
	supportEMail: 'ccp-support@cancom.io',
	trackingId: 2,
	assets: 'https://portal-assets.cancom.io',
	feature: {
		subaccountsEnabled: true,
		corcAlphaEndpoints: true
	},
	api: {
		accountManagement: 'https://myaccount-api.portal.cancom.dev',
		auditLog: 'https://audit-log.portal.cancom.dev',
		azurelz: 'https://az-landing.portal.cancom.dev',
		carRental: 'https://car-rental.portal.cancom.dev',
		cmsmgw: 'https://cmsmgw.cc-mase-interfaces.de/dev',
		cmsmgwaddons: 'https://j2xydo8d6j.execute-api.eu-central-1.amazonaws.com/dev/dev',
		corc: 'https://corc.portal.cancom.dev',
		customerDb: 'https://customer-db.portal.cancom.dev',
		dciCm: 'https://dci-cm.portal.cancom.dev',
		dciNg: 'https://dci-ng.portal.cancom.dev',
		drive: 'https://drive-api.portal.cancom.dev',
		dynamicCloud: 'https://dynamic-cloud.portal.cancom.dev',
		eventbridge: 'https://event-bridge.portal.cancom.dev',
		genericServices: 'https://generic-services.portal.cancom.dev',
		iam: 'https://iam.portal.cancom.dev',
		ipmanagement: 'https://ip-management.portal.cancom.dev',
		managedOS: 'https://managed-os.portal.cancom.dev',
		meteringEngine: 'https://metering-engine.portal.cancom.dev',
		miaMuseum: 'https://mia-museum.portal.cancom.dev',
		serviceRegistry: 'https://service-registry.portal.cancom.dev',
		simpleVpn: 'https://simple-vpn.portal.cancom.dev',
		smsAlert: 'https://sms-notify.portal.cancom.dev',
		sslMonitor: 'https://ssl-monitor.portal.cancom.dev',
		terraformManagement: 'https://tf-management.portal.cancom.dev',
		s3: 'https://s3.portal.cancom.dev',
		tRex: 'https://resource-explorer.portal.cancom.dev',
		webpresence: 'https://domdns.portal.cancom.dev',
		WindowsOS: 'https://windows-os.portal.cancom.dev',
		xaasonazure: 'https://xaas-azure.portal.cancom.dev',
	},
	external: {
		drive: 'https://drive.portal.cancom.dev',
		myaccount: 'https://myaccount.portal.cancom.dev',
	},
	agGridLicenseKey:
		'Using_this_{AG_Grid}_Enterprise_key_{AG-073676}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{CANCOM_Managed_Services_GmbH}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{CANCOM_Cloud_Portal}_only_for_{5}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{CANCOM_Cloud_Portal}_need_to_be_licensed___{CANCOM_Cloud_Portal}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_February_2026}____[v3]_[01]_MTc3MTAyNzIwMDAwMA==89602f881e9ff45a841a377ecd396b12',
};
